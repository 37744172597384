import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import LogRocket from 'logrocket';
import createPlugin from 'logrocket-vuex';
import VueGtm from '@gtm-support/vue2-gtm';
import { http } from './api';
import i18n from './i18n';
import router from './router';

Vue.use(Vuex);

const logrocketPlugin = createPlugin(LogRocket);

// Define all the possible languages. (used in language dropdown).
const possibleLanguages = [
  {
    code: 'nl',
    text: 'Nederlands',
  },
  {
    code: 'en',
    text: 'English',
  },
  {
    code: 'fr',
    text: 'Français',
  },
  {
    code: 'de',
    text: 'Deutsch',
  },
];

function localeToLanguage(locale) {
  return locale.substring(0, 2);
}

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      key: 'VanHoeckePwa',
      paths: ['user', 'isLoggedIn', 'lastDeviceId'],
    }),
    logrocketPlugin,
  ],

  // STATE
  state: {
    // global
    isLoggedIn: false,
    lastDeviceId: null,
    isLoading: false,
    alert: {
      message: '',
      color: '',
    },

    // Multi personality.
    personality: {
      logo: null,
      personalityId: null,
      accentColor: null,
      fittingsAllowed: null,
      languages: [],
      pwaLoginForm: false,
      shopUrl: '',
      readableName: '',
      gtm: '',
      external_ip: '',
      legalLinks: {},
      contactInfo: null,
    },

    // account
    user: {},
    settings: {
      drawer: false,
    },

    // contacts
    contacts: null,

    // autocomplete
    autocompleteItems: null,

    // product
    article: {
      quantity: 1,
      code: null,
      error: null,
      product: null,
      productId: null,
    },

    // cart
    cart: null,
    mercureCart: null,
    activeMercureCartSubscriber: null,
    delivery: null,
  },

  // ACTIONS
  actions: {
    /**
     * @desc: Set a global Alert
     * @message: String
     * @color: String (success, info, error)
     */
    setAlert({ commit }, alert) {
      commit('SET_ALERT', alert);
    },

    setLastDeviceId({ commit }, deviceId) {
      commit('SET_LAST_DEVICE_ID', deviceId);
    },

    /**
     * @desc: Set a global Loading state
     * @loading: Boolean (true = loading)
     */
    setLoading({ commit }, loading) {
      commit('SET_LOADING', loading);
    },

    /**
     * @desc: Settings for the application
     * @drawer: Boolean
     */
    setSettings({ commit }, settings) {
      commit('SET_SETTINGS', settings);
    },

    /**
     *
     * @param commit
     * @param payload
     * @returns {Promise<void>}
     */
    async initPwa({ commit }, payload) {
      try {
        const response = await http.get(`/${i18n.locale}/api/personality-info`);
        commit('SET_PERSONALITY', response.data, payload.setLocale);
      } catch (error) {
        // Default to Van Hoecke if personality was not found.
        if (error && error.response && error.response.status === 404) {
          commit('SET_PERSONALITY', {
            shopUrl: process.env.VUE_APP_API_URL,
            personalityId: 1,
            accentColor: 'CD1719',
            fittingsAllowed: true,
            languages: ['nl', 'en', 'fr', 'de'],
            pwaLoginForm: true,
            readableName: 'Van Hoecke',
            gtm: 'GTM-TTVK4QV',
          }, payload.setLocale);

          // Exit early.
          return;
        }

        // @todo: Extend alert with new prop to show a reload button instead of close button.
        commit('SET_ALERT', {
          message: i18n.t('global.technical_error'),
          color: 'error',
          showReload: true,
        });
      }
    },

    /**
     * @desc: Login to PWA
     * @name: String
     * @pass: String
     * @token: Number
     */
    async login({ commit }, user) {
      try {
        const response = await http.post(`/${i18n.locale}/user/login/mobile?_format=json`, user);
        commit('LOGIN', response.data);
        if (user.token) {
          commit('USED_TOKEN', user.token);
        }
      } catch (error) {
        if (error.response.status === 403) {
          commit('SET_ALERT', {
            message: i18n.t('global.technical_error'),
            color: 'error',
          });
        } else if (error.response && error.response.data.message) {
          commit('SET_ALERT', {
            message: error.response.data.message,
            color: 'error',
          });
        } else {
          commit('SET_ALERT', {
            message: i18n.t('global.technical_error'),
            color: 'error',
          });
        }
        throw Error(error);
      }
    },

    /**
     * @desc: Logout from PWA
     */
    async logout({ commit, state }) {
      try {
        const response = await http.get(`/${i18n.locale}/user/logout/mobile?_format=json`, {
          headers: {
            'X-CSRF-Token': state.user.csrf_token,
          },
        });
        commit('LOGOUT');
        return response.data;
      } catch (error) {
        if (error.response.status === 403) {
          commit('LOGOUT');
        } else if (error.response && error.response.data.message) {
          commit('LOGOUT');
          commit('SET_ALERT', {
            message: error.response.data.message,
            color: 'error',
          });
        } else {
          commit('LOGOUT');
          commit('SET_ALERT', {
            message: i18n.t('global.technical_error'),
            color: 'error',
          });
        }
        throw Error(error);
      }
    },

    /**
     * @desc: Get Autocomplete result
     * @q: String
     */
    async getAutocomplete({ commit }, query) {
      try {
        const response = await http.get(`/${i18n.locale}/product/autocomplete/cart?q=${query}&_format=json`);
        commit('GET_AUTOCOMPLETE_ITEMS', response.data);
      } catch (error) {
        if (error.response.status === 403) {
          commit('SET_ALERT', {
            message: i18n.t('global.technical_error'),
            color: 'error',
          });
          commit('LOGOUT');
          router.push({ name: 'login' }).catch(() => {});
        } else {
          commit('SET_ALERT', {
            message: error.response.data.message,
            color: 'error',
          });
        }
        throw Error(error);
      }
    },

    /**
     * @desc: Clear autocomplete items
     * @q: String
     */
    async clearAutocomplete({ commit }) {
      try {
        commit('CLEAR_AUTOCOMPLETE_ITEMS');
      } catch (error) {
        throw Error(error);
      }
    },

    /**
     * @desc: GET product article EAN
     * @id: Number
     */
    async getArticleInfo({ commit }, params) {
      const { value } = params;
      const { type } = params;

      try {
        const response = await http.get(`/${i18n.locale}/product/info/${type}/${value}?_format=json`);
        commit('GET_ARTICLE_INFO', {
          product: response.data,
          code: value,
        });
      } catch (error) {
        if (error.response.status === 403) {
          commit('SET_ALERT', {
            message: i18n.t('global.technical_error'),
            color: 'error',
          });
          commit('LOGOUT');
          router.push({ name: 'login' }).catch(() => {});
        } else {
          commit('SET_ALERT', {
            message: error.response.data.message,
            color: 'error',
          });
        }
        throw Error(error);
      }
    },
    /**
     * @desc: CLEAR product article INFO
     */
    async clearArticleInfo({ commit }) {
      try {
        commit('CLEAR_ARTICLE_INFO');
      } catch (error) {
        throw Error(error);
      }
    },

    /**
     * @desc: Add product to cart
     * @id: Number
     * @quantity: Number
     */
    async addToCart({ commit }, data) {
      try {
        const response = await http.post(`/${i18n.locale}/cart/add/${data.id}?_format=json`, {
          quantity: data.quantity,
        });
        if (data.redirect) {
          router.push({ name: 'cart' }).catch(() => {});
        }
        commit('GET_CART', response.data);
        if (data.redirect) {
          router.push({ name: 'cart' }).catch(() => {});
        }
      } catch (error) {
        if (error.response.status === 403) {
          commit('SET_ALERT', {
            message: i18n.t('global.technical_error'),
            color: 'error',
          });
          commit('LOGOUT');
          router.push({ name: 'login' }).catch(() => {});
        } else {
          commit('SET_ALERT', {
            message: error.response.data.message,
            color: 'error',
          });
        }
        throw Error(error);
      }
    },

    /**
     * @desc: Remove a product from cart
     * @product: Object
     */
    async deleteFromCart({ commit, dispatch }, product) {
      try {
        const response = await http.delete(`/${i18n.locale}/cart/remove-line/${product.id}?_format=json`);
        commit('GET_CART', response.data);
        return true;
      } catch (error) {
        if (error.response.status === 403) {
          commit('SET_ALERT', {
            message: i18n.t('global.technical_error'),
            color: 'error',
          });
          commit('LOGOUT');
          router.push({ name: 'login' }).catch(() => {});
        } else {
          commit('SET_ALERT', {
            message: error.response.data.messages.error[0],
            color: 'error',
          });
          dispatch('getCart');
        }
      }
      return false;
    },

    /**
     * @desc: Update a product
     * @product: Object
     */
    async updateQuantity({ commit }, product) {
      try {
        const response = await http.put(`/${i18n.locale}/cart/update-line/${product.id}?_format=json`, {
          quantity: parseInt(product.quantity, 10),
        });
        commit('UPDATE_QUANTITY', response.data);
      } catch (error) {
        if (error.response.status === 403) {
          commit('SET_ALERT', {
            message: i18n.t('global.technical_error'),
            color: 'error',
          });
          commit('LOGOUT');
          router.push({ name: 'login' }).catch(() => {});
        } else {
          commit('SET_ALERT', {
            message: error.response.data.messages.error[0],
            color: 'error',
          });
          this.dispatch('getCart');
        }
      }
    },

    /**
     * @desc: Get cart data
     */
    async getCart({ commit }) {
      try {
        const response = await http.get(`/${i18n.locale}/cart?_format=json`);
        commit('GET_CART', response.data);
      } catch (error) {
        console.log(error);

        if (error.response.status === 403) {
          commit('SET_ALERT', {
            message: i18n.t('global.technical_error'),
            color: 'error',
          });
          commit('LOGOUT');
          router.push({ name: 'login' }).catch(() => {});
        } else {
          commit('SET_ALERT', {
            message: error.response.data.message,
            color: 'error',
          });
        }
        throw Error(error);
      }
    },

    async mercureCart({ commit }) {
      // Check if mercure is already subscribed to the current cart.
      if (this.state.cart.id === this.state.activeMercureCartSubscriber) {
        return;
      }

      const url = new URL(process.env.VUE_APP_MERCURE_HUB_URL);
      url.searchParams.append('topic', `/carts/${this.state.cart.id}`);
      const eventSource = new EventSource(url, { withCredentials: false });
      this.state.activeMercureCartSubscriber = this.state.cart.id;
      eventSource.onmessage = ({ data }) => {
        const parsedData = JSON.parse(data);
        if (parsedData.cart?.data && parsedData.cart?.uid === this.state.user.current_user.uid) {
          commit('MERCURE_CART', parsedData.cart.data);
        }
      };
    },

    /**
     * @desc: Prepare cart for order
     */
    async prepareCart({ commit }) {
      try {
        const response = await http.get(`/${i18n.locale}/checkout/prepare-order?_format=json`);
        commit('PREPARE_CART', response.data);

        if (typeof response.data.financial_blocking !== 'undefined' && response.data.financial_blocking) {
          commit('SET_ALERT', {
            message: response.data.financial_blocking,
            color: 'warning',
          });
        }
      } catch (error) {
        if (error.response.status === 403) {
          commit('SET_ALERT', {
            message: i18n.t('global.technical_error'),
            color: 'error',
          });
          commit('LOGOUT');
          router.push({ name: 'login' }).catch(() => {});
        } else {
          router.push({ name: 'cart' }).catch(() => {});
          commit('SET_ALERT', {
            message: error.response.data.message,
            color: 'error',
          });
        }
        throw Error(error);
      }
    },

    /**
     * @desc: Checkout current cart
     */
    async checkoutCart({ commit }) {
      try {
        const response = await http.post(`/${i18n.locale}/checkout/mobile/order?_format=json`);
        return response.data;
      } catch (error) {
        if (error.response.status === 403) {
          commit('SET_ALERT', {
            message: i18n.t('global.technical_error'),
            color: 'error',
          });
          commit('LOGOUT');
          router.push({ name: 'login' }).catch(() => {});
        } else {
          router.push({ name: 'cart' }).catch(() => {});
          commit('SET_ALERT', {
            message: error.response.data.message,
            color: 'error',
          });
        }
        throw Error(error);
      }
    },

    // Rename the shopping cart.
    async renameCart({ commit }, { name, cartId }) {
      try {
        const bodyFormData = new FormData();
        bodyFormData.set('name', name);
        const response = await http.post(`/${i18n.locale}/cart/rename/${cartId}?_format=json`, bodyFormData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response?.data?.error) {
          commit('SET_ALERT', {
            message: response.data.error,
            color: 'error',
          });
          return false;
        }

        // Do not reload the full cart, just change the name.
        commit('UPDATE_CART_NAME', name);
        return true;
      } catch (error) {
        if (error?.response?.data?.error) {
          commit('SET_ALERT', {
            message: error.response.data.error,
            color: 'error',
          });
          this.dispatch('getCart');
          return false;
        }

        commit('SET_ALERT', {
          message: i18n.t('global.technical_error'),
          color: 'error',
        });
      }
      return false;
    },

    /**
     * @desc: Add report damage
     * @image: base64
     * @code: Int
     * @type: String (DMG_VH | DMG_C)
     */
    async addReportDamage({ commit }, report) {
      try {
        const bodyFormData = new FormData();
        for (const i in report.image) {
          bodyFormData.append('image[]', report.image[i]);
        }
        bodyFormData.set('code', report.code);
        bodyFormData.set('type', report.type);
        const response = await http.post(`/${i18n.locale}/cart/report/damage?_format=json`, bodyFormData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        commit('GET_CART', response.data);
      } catch (error) {
        if (error.response.status === 403) {
          commit('SET_ALERT', {
            message: i18n.t('global.technical_error'),
            color: 'error',
          });
          commit('LOGOUT');
          router.push({ name: 'login' }).catch(() => {});
        } else {
          commit('SET_ALERT', {
            message: error.response.data.message,
            color: 'error',
          });
        }
        throw Error(error);
      }
    },

    /**
     * @desc: Get contact data
     */
    async getContacts({ commit }) {
      try {
        const response = await http.get(`/${i18n.locale}/customer?_format=json`);
        commit('GET_CONTACTS', response.data);
      } catch (error) {
        if (error.response.status === 403) {
          commit('SET_ALERT', {
            message: i18n.t('global.technical_error'),
            color: 'error',
          });
          commit('LOGOUT');
          router.push({ name: 'login' }).catch(() => {});
        } else {
          commit('SET_ALERT', {
            message: error.response.data.message,
            color: 'error',
          });
        }
        throw Error(error);
      }
    },

  },

  // MUTATIONS
  mutations: {
    UPDATE_CART_NAME(state, name) {
      if (state.cart) {
        state.cart.name = name;
      }
    },

    // PERSONALITIES
    SET_PERSONALITY(state, data, setLocale = false) {
      http.defaults.baseURL = data.shopUrl;
      state.personality.personalityId = data.personalityId;
      state.personality.accentColor = `#${data.accentColor}`;
      state.personality.fittingsAllowed = data.fittingsAllowed || false;
      state.personality.pwaLoginForm = data.pwaLoginForm || false;
      state.personality.shopUrl = data.shopUrl || '';
      state.personality.readableName = data.readableName || '';
      state.personality.gtm = data.gtm || '';
      state.personality.external_ip = data.external_ip || '';
      state.personality.contactInfo = data.contactInfo || null;

      if (process.env.NODE_ENV === 'production') {
        Vue.use(VueGtm, {
          id: state.personality.gtm,
          debug: false,
          enabled: true,
          vueRouter: router,
          // source: 'https://www.googletagmanager.com/gtm.js',
          // source: state.personality.personalityId > 1 ? 'https://www.googletagmanager.com/gtm.js' : 'https://gtm.vanhoecke.be/gtm.js',
          source: state.personality.personalityId > 1 ? 'https://gtm.drawerconfigurator.com/gtm.js' : 'https://gtm.vanhoecke.be/gtm.js',
        });
      }

      // Set locale. Fallback to English.
      if (setLocale) {
        i18n.locale = data.languages ? data.languages[0] : 'en';
      }

      const personalityLanguages = data.languages.map((locale) => localeToLanguage(locale));

      // Set enabled languages for this personality.
      state.personality.languages = possibleLanguages.filter((lang) => (personalityLanguages || []).includes(lang.code));

      if (data.logoUrl) {
        state.personality.logo = `${data.shopUrl}/${i18n.locale}${data.logoUrl}`;
      }

      if (data.faviconUrl) {
        state.personality.favicon = `${data.shopUrl}/${i18n.locale}${data.faviconUrl}`;
      }

      // Only VH for now.
      if (data.legalLinks && state.personality.personalityId === 1) {
        for (const [key, value] of Object.entries(data.legalLinks)) {
          Vue.set(state.personality.legalLinks, key, value);
        }
      }

      // state.personality.logo = '/images/hafele.svg';
    },

    // GENERAL
    SET_ALERT(state, alert) {
      state.alert.message = alert.message;
      state.alert.color = alert.color;
      state.alert.showReload = alert.showReload || false;
    },
    SET_LOADING(state, loading) {
      state.isLoading = loading;
    },
    SET_SETTINGS(state, settings) {
      state.settings = {
        ...state.settings,
        ...settings,
      };
    },

    SET_LAST_DEVICE_ID(state, id) {
      state.lastDeviceId = id;
    },

    // LOGIN
    LOGIN(state, user) {
      state.user = user;
      state.isLoggedIn = !!user;
    },
    LOGOUT(state) {
      state.user = null;
      state.isLoggedIn = false;
      localStorage.removeItem('VanHoeckePwa');
    },
    USED_TOKEN(state, token) {
      state.user.usedToken = token;
    },

    // CART
    GET_CART(state, cart) {
      state.cart = cart;
    },
    MERCURE_CART(state, cart) {
      state.mercureCart = cart;
    },
    UPDATE_QUANTITY(state, cart) {
      state.cart.total = cart.total;
    },
    PREPARE_CART(state, response) {
      state.cart = response.cart;
      state.delivery = {
        address: response.delivery_address,
        date: response.delivery_date,
      };
    },

    // GET AUTOCOMPLETE ITEMS
    GET_AUTOCOMPLETE_ITEMS(state, response) {
      state.autocompleteItems = null;
      state.autocompleteItems = response;
    },
    CLEAR_AUTOCOMPLETE_ITEMS(state) {
      state.autocompleteItems = null;
    },

    // SET ARTICLE INFO
    GET_ARTICLE_INFO(state, article) {
      state.article.quantity = parseInt(article.product.minimumQuantity, 10);
      state.article.code = article.code;
      state.article.product = article.product;
      state.article.productId = article.product.id;
    },

    CLEAR_ARTICLE_INFO(state) {
      state.article = {
        quantity: 1,
        code: null,
        error: null,
        product: null,
        productId: null,
      };
    },

    // CONTACT
    GET_CONTACTS(state, contacts) {
      state.contacts = contacts;
    },
  },

});
